@SJgreen: #5acc11; //#01cc01;
@SJgreenHover: #3d9159;
@SJBlue: #256f9b;// #079bef;
@mgray: #dfdfdf;
@SJSmaragd: #50c878;

body {
  font-family: 'Roboto', sans-serif;//'Poppins', 'Times New Roman', Times, serif;
  font-size: 1.1rem;
  font-display: swap;
}

.ma-section-tile {
    padding-top: 3em;
    padding-bottom: 1.5em;
    min-height: 30vh;
}

.ma-attribute {
    background-color: #3d9159;
    border-radius: 1em;
    padding: 1.5em;
    height: 13em;
    color: white;
    border: 0.1em solid @SJSmaragd;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    .item-head {
      border-bottom: 0.15em solid;
    }
}

.ma-attribute:hover {
  transform: scale(1.02);
}

.ma-attribute-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.ma-attribute-icon {
  display: inline-block;
}

.ma-attribute-label {
  font-weight: 600;
  font-size: 1.5rem;
}

.ma-btn {
    background-color: @SJBlue;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    border: 0.1em;
    border-radius: 0.25em;
    color: #fff;
}

.ma-btn:hover {
    background-color: @SJgreenHover;
    color: white;
}

.ma-hover:hover {
  transform: scale(1.02);
}

.btn-primary {
  background-color: @SJBlue;// #38B6FF;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: black;
}

.btn-primary:hover {
  background-color: @SJgreenHover;
  color: white;
}

/* Dropdown */

.navbar .menu_nav .nav-item .dropdown-menu .dropdown-item {
  color: #ffffff;
  text-transform: uppercase;
}

.navbar .menu_nav .nav-item:hover .nav-link,
.navbar .menu_nav .dropdown .dropdown-menu .dropdown-item-li:hover .dropdown-item {
  color: #000;
  background-color: transparent;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
} 

.dropdown-item.active, .dropdown-item:active 
{
  background-color: transparent;
}

@media all and (min-width: 992px) {
	.navbar .dropdown-menu-end{ right:0; left: auto;  }
	.navbar .nav-item .dropdown-menu{  display:block; opacity: 0;  visibility: hidden; transition:.3s; margin-top:0;  }
	.navbar .nav-item:hover .nav-link{ color: #fff;  }
	.navbar .dropdown-menu.fade-down{ top:80%; transform: rotateX(-75deg); transform-origin: 0% 0%; }
	.navbar .dropdown-menu.fade-up{ top:180%;  }
	.navbar .nav-item:hover .dropdown-menu{ transition: .3s; opacity:1; visibility:visible; top:100%; transform: rotateX(0deg); }
}

.navbar .menu_nav .nav-item .nav-link:after {
  display: none;
}

@media (max-width: 992px) {
  .navbar .menu_nav .nav-item .nav-link:after {
    display: inline-block;
    margin-left: 15%;
  }

  .navbar .menu_nav .nav-item .dropdown-menu .dropdown-item {
    font: 500 1rem/2em "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.55);
    border-bottom: 1px solid;
    padding-bottom: 0.2em;
    padding-top: 0.2em;
  }

  .navbar .menu_nav .nav-item .dropdown-menu li:last-child .dropdown-item {
    border-bottom: 0px solid;
  }
}

/* end */

@media (max-width: 1400px) { 
  .ma-attribute {
    height: 15em;
  
    .ma-attribute-text {
      -webkit-line-clamp: 6;
    }
  }
}

@media (max-width: 1200px) { 
  .ma-attribute {
    height: 19em;

    .ma-attribute-text {
      -webkit-line-clamp: 7;
    }
  }
}

@media (max-width: 991px) { 
    .ma-attribute {
      height: 16em;
    }
}

@media (max-width: 767px) { 
  .ma-attribute {
    height: 12em;
  }
}

@media (max-width: 576px) { 
  .ma-attribute {
    height: 14em;
  }
}

@media (max-width: 417px) { 
  .ma-attribute {
    height: auto;
    width: auto;
  }
}

@keyframes opacityAnimation{
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes opacityAnimation {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes opacityAnimation {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes opacityAnimation {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes opacityAnimation {
  from { opacity: 0; }
  to   { opacity: 1; }
}