@import "./../../styles.less";

.ma-contact-btn {
    margin: 1em 0 0.5em;
    button {
        outline: medium none;
        padding: 0.5em 0.75em;
        text-decoration: none;
        text-transform: uppercase;
        background: @SJBlue;
        color: #fff;
        border-radius: 0;
    }

    button:before {
      background: #f8b100;
      -webkit-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
      opacity: 0;
      -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      transform: translateX(-100%);
      z-index: -1;
    }
}

.ma-contact-btn button:hover {
    background: @SJgreenHover;
    color: white;
    transition: all 0.3s linear;
} 

.ma-contact-btn button,
.ma-contact-btn:hover button {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
} 